import forEach from 'lodash/forEach';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import {
  FORM_BUILDER_RELEASE_TYPE__PATCH,
  FORM_BUILDER_RELEASE_TYPE__MINOR,
  FORM_BUILDER_RELEASE_TYPE__MAJOR,
} from '../constants';
import { escapeRegExp } from './text';

const toNumber = (string) => parseInt(string, 10);

const compareNumbers = (value1, value2) => {
  if (value1 < value2) {
    return -1;
  }
  if (value1 > value2) {
    return 1;
  }
  return 0;
};

function getIndex(releaseType) {
  switch (releaseType) {
    case FORM_BUILDER_RELEASE_TYPE__MAJOR:
      return 0;
    case FORM_BUILDER_RELEASE_TYPE__MINOR:
      return 1;
    case FORM_BUILDER_RELEASE_TYPE__PATCH:
      return 2;
    default:
      return -1;
  }
}

export function normalizeVersion(version) {
  const parts = version.split('.');
  const newParts = [];
  let i = 0;
  while (i < 3) {
    newParts.push(parts[i] || '0');
    i += 1;
  }
  return newParts.join('.');
}

export function nextVersionString(version, releaseType) {
  const parts = version.split('.').map(toNumber);
  const index = getIndex(releaseType);
  const newParts = [];
  // NOTE: If releaseType is unknown, we prefer to act as identity.
  const n = index >= 0 ? index : parts.length;
  let i = 0;
  while (i < n) {
    newParts.push(parts[i] || 0);
    i += 1;
  }
  if (index >= 0) {
    newParts.push((parts[index] || 0) + 1);
  }
  while (newParts.length < 3) {
    newParts.push(0);
  }
  return newParts.join('.');
}

export function compareVersions(version1, version2) {
  const parts1 = version1.split('.').map(toNumber);
  const parts2 = version2.split('.').map(toNumber);
  let i = 0;
  while (i < parts1.length && i < parts2.length) {
    if (parts1[i] !== parts2[i]) {
      return compareNumbers(parts1[i], parts2[i]);
    }
    i += 1;
  }
  return compareNumbers(parts1[i] || 0, parts2[i] || 0);
}

export function findMaxVersion(versions) {
  if (isEmpty(versions)) {
    return undefined;
  }
  const n = versions.length;
  let maxVersion = versions[0];
  for (let i = 1; i < n; i += 1) {
    if (compareVersions(maxVersion, versions[i]) < 0) {
      maxVersion = versions[i];
    }
  }
  return maxVersion;
}

export function compareReleaseTypes(type1, type2) {
  const value1 = getIndex(type1);
  const value2 = getIndex(type2);
  return compareNumbers(value1, value2);
}

export function isReleaseTypeAllowed(allowedReleaseType, releaseType) {
  return compareReleaseTypes(allowedReleaseType, releaseType) <= 0;
}

export function toRegExpString(versionRange) {
  const chunks = [];
  if (versionRange) {
    forEach(versionRange.split('.'), (part) => {
      if (part === '*' || part === 'x' || part === 'X') {
        chunks.push('(\\d+)');
      } else {
        chunks.push(escapeRegExp(part));
      }
    });
  }
  while (chunks[chunks.length - 1] === '(\\d+)') {
    chunks.pop();
  }
  if (chunks.length === 0) {
    return '\\d+(\\.\\d+){0,2}';
  }
  if (chunks.length === 1) {
    return `${chunks[0]}(\\.\\d+){0,2}`;
  }
  if (chunks.length === 2) {
    return `${chunks[0]}\\.${chunks[1]}(\\.\\d+){0,1}`;
  }
  return chunks.join('\\.');
}

export function findMatchingVersions(range, versions) {
  const re = new RegExp(`^${toRegExpString(range)}`);
  return filter(versions, (v) => re.test(v));
}

export function findMaxMatchingVersion(range, versions) {
  return findMaxVersion(findMatchingVersions(range, versions));
}

export const getVersion = (id) => id && id.split('@')[1];

export const getIdentifier = (id) => id && id.split('@')[0];

export const splitQuestionnaireId = (id) => (id ? id.split('@') : []);

export const isMatchingVersion = (range, version) => {
  const re = new RegExp(`^${toRegExpString(range)}`);
  return re.test(version);
};

export const identifierAndVersionToRegExpSource = (identifier, version) => {
  return `^${escapeRegExp(identifier)}@${toRegExpString(version)}`;
};

export const identifierAndVersionToRegExp = (identifier, version) => {
  const source = identifierAndVersionToRegExpSource(identifier, version);
  return new RegExp(source);
};

export const mapQuestionnaireIdToRegExp = (questionnaireId) => {
  const [identifier, version] = splitQuestionnaireId(questionnaireId);
  return identifierAndVersionToRegExpSource(identifier, version);
};

export const isMatchingQuestionnaireId = (
  generalizedQuestionnaireId,
  questionnaireId,
) => {
  if (isNil(generalizedQuestionnaireId)) {
    return true;
  }
  const regexp = new RegExp(
    mapQuestionnaireIdToRegExp(generalizedQuestionnaireId),
  );
  return !!regexp.test(questionnaireId);
};

export const questionnaireBaseIdentifierRegEx = /^[a-z_][a-z\-_\d]*$/;

export const questionnaireIdentifierRegEx = /^[a-z_][a-z\-_/\d]*@\d+(\.\d+)*$/;

export const questionnaireTranslationIdentifierRegEx =
  /^[a-z_][a-z\-_/\d]*@\d+(\.\d+)*\/i18n\/[a-z]{2}\/\d+$/;

export const generalizedQuestionnaireIdentifierRegEx =
  /^[a-z_][a-z_\-/\d]*@(x|\d+)(\.(x|\d+))*$/;
