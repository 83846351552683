/**
 * Parse query strings but only in their simplest form.
 *
 * @param {string} search
 * @returns {object} Object mapping key to value.
 * @example
 * parse('?a=1&b=2&c=3') // { a: 1, b: 2, c: 3 }
 */
export const parse = (search) => {
  if (!search) {
    return {};
  }
  if (search.charAt(0) === '?') {
    return parse(search.substr(1));
  }
  const parts = search.split('&');
  const query = {};
  parts.forEach((part) => {
    const [key, value] = part.split('=');
    if (value !== undefined) {
      query[decodeURIComponent(key)] = decodeURIComponent(value);
    }
  });
  return query;
};

/**
 * Encode query object in a form of querystring.
 *
 * @param {string} query
 * @returns {string} encoded querystring
 * @example
 * encode({ a: 1, b: 2, c: 3 }) // '?a=1&b=2&c=3'
 */
export const encode = (query) => {
  if (!query) {
    return '';
  }
  const parts = Object.keys(query)
    .filter((key) => query[key] !== undefined && query[key] !== null)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`,
    );
  if (parts.length === 0) {
    return '';
  }
  return `?${parts.join('&')}`;
};
