import Formula from '../Formula';
import {
  FORMULA_TYPE__UNARY,
  FORMULA_OPERATOR__NOT,
  FORMULA_OPERATOR__MINUS,
} from '../../../constants';
import Schema from '../../../utils/Schema';

const operators = {
  [FORMULA_OPERATOR__NOT]: (x) => !x,
  [FORMULA_OPERATOR__MINUS]: (x) => -x,
};

const settingsSchema = new Schema({
  operator: {
    type: String,
  },
  right: {
    type: Object,
    additionalProperties: true,
  },
});

class FormulaUnary extends Formula {
  validate() {
    if (!this.settings) {
      return this.constructor.NotConfigured;
    }
    if (settingsSchema.getErrors(this.settings)) {
      return this.constructor.NotConfigured;
    }
    return undefined;
  }

  evaluate(scope) {
    const op = operators[this.settings.operator];
    if (!op) {
      return {
        error: this.constructor.UnknownOperator,
      };
    }
    const { value, error } = Formula.create(this.settings.right).evaluate(
      scope,
    );
    if (error) {
      if (this.settings.operator === FORMULA_OPERATOR__NOT) {
        return {
          value: true,
        };
      }
      return {
        error,
      };
    }
    return {
      value: op(value),
    };
  }

  compile(questionsHierarchy) {
    const compiled = {
      ...this,
      settings: {
        operator: this.settings.operator,
      },
    };
    if (this.settings && this.settings.right) {
      compiled.settings.right = Formula.create(this.settings.right).compile(
        questionsHierarchy,
      );
    }
    return compiled;
  }

  toMongoExpression() {
    let operator;
    switch (this.settings.operator) {
      case FORMULA_OPERATOR__NOT:
        operator = '$not';
        break;
      default:
      // ...
    }
    if (!operator || !this.settings.right) {
      return {
        $literal: '[unknown]',
      };
    }
    return {
      [operator]: this.settings.right.toMongoExpression(),
    };
  }

  static createMapSettings(mapQuestionId) {
    return (value, key) => {
      switch (key) {
        case 'right':
          return Formula.create(value).remap(mapQuestionId).toRawFormula();
        default:
          return value;
      }
    };
  }

  static not(formula) {
    return new this({
      type: FORMULA_TYPE__UNARY,
      settings: {
        operator: FORMULA_OPERATOR__NOT,
        right: formula.toRawFormula(),
      },
    });
  }
}

Formula.types[FORMULA_TYPE__UNARY] = FormulaUnary;

export default FormulaUnary;
