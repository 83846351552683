import omit from 'lodash/omit';
import forEach from 'lodash/forEach';
import isPlainObject from 'lodash/isPlainObject';
import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';
import { createSelector } from 'reselect';
import { PURGE } from 'redux-persist';
import gql from 'graphql-tag';
import some from 'lodash/some';
import every from 'lodash/every';
import settings from '../common/settings';
import toSelector from '../common/utils/toSelector';
import { getUserLanguage } from './preferences';
import { setToken } from './token';

const {
  appName = 'zedoc-patient-web',
  appCommitSha,
  completionStatusPollSeconds = 3,
} = settings.public;

export const ACTION_UPLOAD = '@STAGE/UPLOAD';
export const ACTION_DELETE = '@STAGE/DELETE';
export const ACTION_UPDATE = '@STAGE/UPDATE';
export const ACTION_DELETE_ALL = '@STAGE/DELETE_ALL';

export const STATE_INITIAL = 'INITIAL';
export const STATE_DRAFT = 'DRAFT';
export const STATE_ACTIVE = 'ACTIVE';
export const STATE_ERROR = 'ERROR';
export const STATE_READY = 'READY';

export const getStaged = (state) => state.stage;

export const isNotCompleted = (staged) => (answersSheet) => {
  const { id, state } = answersSheet;
  if (state === 'COMPLETED') {
    return false;
  }
  return (
    !staged[id] ||
    staged[id].state === STATE_DRAFT ||
    staged[id].state === STATE_INITIAL
  );
};

export const isCompleted = (staged) => (answersSheet) => {
  if (!answersSheet) {
    return false;
  }
  const { id, state } = answersSheet;
  if (state === 'COMPLETED') {
    return true;
  }
  return (
    staged[id] &&
    staged[id].state !== STATE_DRAFT &&
    staged[id].state !== STATE_INITIAL
  );
};

export const load = (selectId) =>
  createSelector(
    (state) => state && state.stage,
    toSelector(selectId),
    (stage, id) => id && stage && stage[id],
  );

export const createSelectTranslationId = (selectId) =>
  createSelector(
    load(selectId),
    (rawAnswersSheet) => rawAnswersSheet && rawAnswersSheet.translationId,
  );

export const createSelectTranslationLanguage = (selectId) =>
  createSelector(
    load(selectId),
    (rawAnswersSheet) => rawAnswersSheet && rawAnswersSheet.language,
  );

export const save = (
  id,
  {
    version = new Date().toISOString(),
    responses,
    variables,
    previousResponses,
    completionRate,
  },
) => ({
  type: ACTION_UPDATE,
  payload: omitBy(
    {
      version,
      responses,
      variables,
      previousResponses,
      completionRate,
      state: STATE_DRAFT,
    },
    isUndefined,
  ),
  meta: {
    id,
  },
});

export const deleteDraft = (id) => ({
  type: ACTION_DELETE,
  meta: {
    id,
  },
});

export const deleteAllDrafts = () => ({
  type: ACTION_DELETE_ALL,
});

export const setTranslation = (id, translationId, language, languages) => ({
  type: ACTION_UPDATE,
  payload: {
    language,
    languages,
    translationId,
  },
  meta: {
    id,
  },
});

export const submit = (id, { responses }) => ({
  type: ACTION_UPLOAD,
  payload: {
    responses,
  },
  meta: {
    id,
  },
});

const getTimezone = () => {
  try {
    // https://stackoverflow.com/a/44935836/2817257
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (err) {
    return undefined;
  }
};

export const createMiddleware =
  ({ client }) =>
  (store) => {
    // https://www.apollographql.com/docs/react/api/apollo-client/#ApolloClient.watchQuery
    const observable = client.watchQuery({
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      pollInterval: 1000 * completionStatusPollSeconds,
      query: gql`
        query GetAnswersSheets {
          # fetch all answers sheets currently assigned to the recipient
          my {
            id
            answersSheets {
              id
              state
            }
          }
        }
      `,
    });

    const onAnswersSheetsStateChange = ({ data }) => {
      const state = store.getState();
      if (!data.my) {
        // NOTE: This effectively logs out the current session;
        //       all local data will be removed.
        store.dispatch(setToken(null));
      }
      forEach(data.my && data.my.answersSheets, (answersSheet) => {
        const staged = load(answersSheet.id)(state);
        if (staged) {
          if (answersSheet.state === 'COMPLETED') {
            // NOTE: We delete draft for AS that is confirmed completed by the server.
            //       We need to use the timeout because in some situations ApolloClient
            //       seems to have a problem updating the cache synchronously even though
            //       client.writeQuery() looks like a synchronous method. Perhaps
            //       their manager.broadcastQueries() isn't synchronous but this is an implementation
            //       detail which is hard to reason about:
            //       https://github.com/apollographql/apollo-client/blob/main/src/core/ApolloClient.ts#L426
            setTimeout(() => {
              store.dispatch(deleteDraft(answersSheet.id));
            }, 1000);
          } else if (staged.state === STATE_ERROR) {
            // NOTE: Retry upload to the server, because we cannot be sure what really happened.
            store.dispatch({
              type: ACTION_UPLOAD,
              meta: {
                id: answersSheet.id,
              },
            });
          }
        }
      });
    };

    const changeActiveToError = (error = 'Upload was not completed') => {
      const staged = getStaged(store.getState());
      forEach(staged, ({ state }, id) => {
        if (state === STATE_ACTIVE) {
          // NOTE: If anything is active on initial load, it probably
          //       means that the upload was terminated for some reason.
          //       So we put item in "error" state in order to retry.
          store.dispatch({
            type: ACTION_UPDATE,
            payload: {
              state: STATE_ERROR,
              error,
            },
            meta: {
              id,
            },
          });
        }
      });
    };

    const uploadDraft = (answersSheetId) => {
      const state = store.getState();
      const draft = load(answersSheetId)(state);
      if (!draft || draft.state !== STATE_DRAFT) {
        return Promise.resolve();
      }
      const {
        responses,
        variables,
        previousResponses,
        version,
        completionRate,
        translationId,
      } = draft;
      return Promise.resolve()
        .then(() =>
          client.mutate({
            mutation: gql`
              mutation PrepareUploadUrl($input: ObtainPreSignedUrlInput) {
                obtainPreSignedUrlForDraft(input: $input)
              }
            `,
            variables: {
              input: {
                answersSheetId,
              },
            },
          }),
        )
        .then(({ data: { obtainPreSignedUrlForDraft } }) =>
          fetch(obtainPreSignedUrlForDraft, {
            method: 'PUT',
            headers: {
              'Content-type': 'application/json',
            },
            body: JSON.stringify({
              encodedDraft: JSON.stringify({
                responses,
                variables,
                previousResponses,
                version,
                completionRate,
                translationId,
              }),
              encodedDraftOptions: {},
            }),
          }),
        )
        .then((response) => {
          if (response.status === 200) {
            return response.text();
          }
          return Promise.reject(new Error(response.statusText));
        })
        .catch((err) => {
          console.error(err);
        });
    };

    const scheduledJobs = {};
    const scheduleDraftUpload = (answersSheetId) => {
      if (!scheduledJobs[answersSheetId]) {
        scheduledJobs[answersSheetId] = setTimeout(() => {
          uploadDraft(answersSheetId);
          delete scheduledJobs[answersSheetId];
        }, 5 * 1000);
      }
    };

    const isWaitingForConfirmation = (draft) =>
      draft.state === STATE_READY || draft.state === STATE_ERROR;
    const isNotWaitingForConfirmation = (draft) =>
      !isWaitingForConfirmation(draft);

    let subscription;
    const maybeSubscribe = (force) => {
      if (!subscription) {
        const staged = getStaged(store.getState());
        if (force || some(staged, isWaitingForConfirmation)) {
          subscription = observable.subscribe(onAnswersSheetsStateChange);
        }
      }
    };

    const maybeUnsubscribe = (force) => {
      if (subscription) {
        const staged = getStaged(store.getState());
        if (force || every(staged, isNotWaitingForConfirmation)) {
          subscription.unsubscribe();
          subscription = null;
        }
      }
    };

    window.addEventListener('online', () => {
      // NOTE: Make sure that we are subscribed because there may be some drafts
      //       in "error" state, which require re-uploading.
      maybeSubscribe();
    });

    return (next) => (action) => {
      if (!isPlainObject(action)) {
        return next(action);
      }
      switch (action.type) {
        case 'persist/REHYDRATE': {
          const result = next(action);
          changeActiveToError();
          maybeSubscribe();
          return result;
        }
        case ACTION_UPDATE: {
          const id = action.meta && action.meta.id;
          if (action.payload && action.payload.version) {
            scheduleDraftUpload(id);
          }
          // NOTE: maybeSubscribe will rely on the next state, so we need
          //       to dispatch the current action first. We are delaying
          //       the request to give server a little bit of time to post-process
          //       the response file on s3 and change the AS state in DynamoDB.
          const result = next(action);
          setTimeout(maybeSubscribe, 1000 * completionStatusPollSeconds);
          return result;
        }
        case ACTION_DELETE:
        case ACTION_DELETE_ALL: {
          const result = next(action);
          maybeUnsubscribe();
          return result;
        }
        case ACTION_UPLOAD: {
          const id = action.meta && action.meta.id;
          const state = store.getState();
          const uiLanguage = getUserLanguage(state);
          // const uiLanguages = ???;
          const payload = {
            ...load(id)(state),
            ...action.payload,
          };
          // NOTE: Let's not forget about pushing the action down the middleware chain,
          //       in order to properly update state.
          next({
            ...action,
            payload,
          });
          return Promise.resolve()
            .then(() =>
              client.mutate({
                mutation: gql`
                  mutation PrepareUploadUrl($input: ObtainPreSignedUrlInput) {
                    obtainPreSignedUrl(input: $input)
                  }
                `,
                variables: {
                  input: {
                    answersSheetId: id,
                  },
                },
              }),
            )
            .then(({ data: { obtainPreSignedUrl } }) =>
              fetch(obtainPreSignedUrl, {
                method: 'PUT',
                headers: {
                  'Content-type': 'application/json',
                },
                body: JSON.stringify({
                  encodedResultsOptions: {},
                  encodedResults: JSON.stringify({
                    responses: payload.responses,
                    variables: payload.variables,
                  }),
                  encodedMetadataOptions: {},
                  encodedMetadata: JSON.stringify({
                    userAgent: navigator.userAgent,
                    timezone: getTimezone(),
                    uiLanguage,
                    // uiLanguages,
                    language: payload.language,
                    languages: payload.languages,
                    translationId: payload.translationId,
                    appName,
                    appCommitSha,
                  }),
                }),
              }),
            )
            .then((response) => {
              if (response.status === 200) {
                client.writeQuery({
                  query: gql`
                    query GetAnswersSheet($id: String!) {
                      answersSheet(id: $id) {
                        id
                        state
                      }
                    }
                  `,
                  data: {
                    answersSheet: {
                      __typename: 'AnswersSheet',
                      id,
                      state: 'COMPLETED',
                    },
                  },
                  variables: {
                    id,
                  },
                });
                store.dispatch({
                  type: ACTION_UPDATE,
                  payload: {
                    state: STATE_READY,
                    error: null,
                    // NOTE: If upload to s3 is confirmed, there's no risk
                    //       AS data is lost so we can safely remove responses
                    //       from local storage. However, we don't want to remove
                    //       all metadata yet as it breaks the ui (form errors) in CardFinish.
                    //       If we can resolve that at a later stage, we should be able to
                    //       remove the entire draft document immediately.
                    responses: [],
                  },
                  meta: {
                    id,
                  },
                });
                return response.text();
              }
              return Promise.reject(new Error(response.statusText));
            })
            .catch((err) => {
              store.dispatch({
                type: ACTION_UPDATE,
                payload: {
                  state: STATE_ERROR,
                  error: err.toString(),
                },
                meta: {
                  id,
                },
              });
              console.error(err);
            });
        }
        default:
          return next(action);
      }
    };
  };

const initialState = {};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PURGE:
    case ACTION_DELETE_ALL:
      return initialState;
    default:
    // ...
  }
  const id = action.meta && action.meta.id;
  if (!id) {
    return state;
  }
  switch (action.type) {
    case ACTION_UPLOAD:
      return {
        ...state,
        [id]: {
          ...state[id],
          ...action.payload,
          error: null,
          state: STATE_ACTIVE,
        },
      };
    case ACTION_UPDATE: {
      return {
        ...state,
        [id]: {
          state: state.state || STATE_INITIAL,
          ...state[id],
          ...action.payload,
        },
      };
    }
    case ACTION_DELETE: {
      if (!state[id]) {
        return state;
      }
      return omit(state, id);
    }
    default:
      return state;
  }
};
